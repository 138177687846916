import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react"
import { isEqual } from "lodash"
import { OE, OeInformationItemState } from "@tm/models"
import { ArticleItemStateProvider } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { OeInformationArticle } from "."
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { useOeBasketQuantities } from "../../hooks/useBasketQuantities"
import { useHandleAddOeToBasket } from "../../hooks/useArticleItem/useHandleAddOeToBasket"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { mapOeArticleDtoFromOePart } from "../../helpers"
import { mapArticleAttributes, mapOePrices } from "../../hooks/useArticleItem/mapper"
import { useOePartErpInfos } from "../../hooks/useArticleItem/useOeArticleErpInfos"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useLoadOePartErpInfos } from "../../hooks/useErpInfos/useLoadOePartErpInfos"
import { usePartsModuleState } from "../../PartsModuleState"
import { useHidePriceTypes } from "@tm/context-distribution"

type Props = {
    article: OeInformationArticle
}

export function OeInformationItemStateProvider({ article: { manufacturer, parts }, children }: PropsWithChildren<Props>) {
    const {
        oeInformation: { showOePrice, displayVehicleOeCriteria, transferToShoppingCart },
    } = useArticleListConfiguration()

    const [selectedPart, setSelectedPart] = useState<OE.OePart | undefined>()

    const { basketQuantities, updateBasketQuantities } = useOeBasketQuantities(parts, !!transferToShoppingCart)

    const [quantity, setQuantity] = useState<number>(selectedPart?.quantity || 1)
    const handleAddToBasketInternal = useHandleAddOeToBasket(selectedPart)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()
    const { translateText } = useLocalization()

    const basketQuantity = useMemo(() => {
        if (!selectedPart || !transferToShoppingCart) {
            return undefined
        }

        const refArticle = mapOeArticleDtoFromOePart(selectedPart)

        return basketQuantities.find((q) => isEqual(q.article, refArticle))
    }, [basketQuantities, selectedPart])

    const postAddToBasket = useCallback(() => {
        if (!selectedPart) {
            return
        }
        updateBasketQuantities([selectedPart])
    }, [selectedPart, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            postAddToBasket()
        },
        [handleAddToBasketInternal, quantity, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (selectedPart && basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateBasketQuantities([selectedPart])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateBasketQuantities, selectedPart])

    const erpInfosData = usePartsModuleState((x) => x.erpInfosData)

    const loadOePartErpInfos = useLoadOePartErpInfos(erpInfosData)
    const handleChangeQuantity = useCallback(
        (value: number) => {
            if (loadOePartErpInfos && selectedPart) {
                setQuantity(value)
                loadOePartErpInfos([{ oePart: selectedPart, quantity: value }])
            }

            setQuantity(value)
        },
        [selectedPart]
    )

    useEffect(() => {
        const defaultPart = parts?.first()
        if (!defaultPart) {
            return
        }
        setSelectedPart(defaultPart)
        setQuantity(defaultPart.quantity && defaultPart.quantity > 0 ? defaultPart.quantity : 1)
    }, [parts])

    const handlePartChange = useCallback(
        (id: string) => {
            const changedSelectedPart = parts?.find((p) => p.id === id)

            if (changedSelectedPart) {
                setSelectedPart(changedSelectedPart)
            }
        },
        [setSelectedPart, parts]
    )

    const articleErpInfos = useOePartErpInfos(selectedPart, quantity || 1, erpInfosData)

    const { isPriceTypeHidden } = useHidePriceTypes()

    if (!selectedPart) {
        return null
    }

    const attributes = mapArticleAttributes(translateText, selectedPart, displayVehicleOeCriteria)
    const oePrices = mapOePrices(selectedPart, showOePrice)
    const options = { isPriceTypeHidden, showDataSupplierLogos: !!(selectedPart.manufacturerThumbnail || selectedPart.thumbnailUrl) }
     /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: OeInformationItemState = {
        type: "oeInformation",
        showAddToBasket: !!transferToShoppingCart,
        isVehicleDependent: false, // TODO once article list v2 opens in modal
        showReplaceButton: false,
        quantity,
        originalQuantity: quantity,
        basketQuantity,
        parts,
        selectedPart,
        oeArticleNo: selectedPart.number,
        thumbnailUrl: manufacturer.thumbnail ?? "/styles/base/images/oe-part-logo.svg",
        description: selectedPart.description,
        attributes,
        oePrices,
        articleErpInfos,
        options,
        handleChangeQuantity,
        handleAddToBasket,
        handlePartChange,
        handleRemoveFromBasket,
        AvailabilityComponent,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}
